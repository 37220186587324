import React from "react";
import { HamburgerSpin } from "react-animated-burgers";
import styled from "styled-components";
import { Link } from "gatsby";
import CTALink from "../CTALink";

const Main = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100px;

  ul {
    li {
      padding-left: 50px;
      color: #000;
      text-align: left;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 900px) { 
    ul {
    li {
      padding-left: 25px;
      
    }
  }
  }

`;

const Base = styled.div`
  position: absolute;
  top: 25px;
  right: 15px;

  span {
    height: 3px;
    color: #ff0000;
  }
  transition: top ease 0.4s;
  &.isactive {
    top: 20px;
  }

  button {
    outline:none;  
    border: none;
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  */
`;

const Overlay = styled.div`
  position: fixed;
  top: 85px;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  opacity: 0;

  &.hide {
    pointer-events: none;
    animation: fadeout ease-in 0.3s 1 normal forwards;
  }

  &.show {
    pointer-events: auto;
    animation: fadein ease-out 0.3s 1 normal forwards;
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;




class MobileMenu extends React.Component {
  state = {
    isActive: false,
  };

  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive,
    });

    //this.props.onOpen();
  };

  render() {
    const { status, language } = this.props;

    return (
      <Main>
        <Overlay
          className={`overlay ${
            this.state.isActive === true ? "show" : "hide"
          }`}>
          <Wrapper>

         

         
            <ul>
              {this.props.navMenuItems.map((i, index) => (
                <li key={'hli'+index}>
                  <CTALink {...i} buttonActionClass={this.props.navActionClass} />
                </li>
              ))}
            </ul>
           


          </Wrapper>
        </Overlay>

        <Base className={` ${this.state.isActive === true ? "isactive" : ""}`}>
          <HamburgerSpin
            buttonWidth={30}
            isActive={this.state.isActive}
            toggleButton={this.toggleButton}
          />
        </Base>
      </Main>
    );
  }
}

export default MobileMenu;
