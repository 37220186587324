import { Link } from "gatsby";
import React from "react";


import styled from 'styled-components'

const Wrapper = styled.div`
position: fixed;
bottom: 0px;
left: 0px;
z-index: 9999;
width: 100%;
display: flex;
align-items: left;
justify-content: space-between;
padding-left: 50px;
padding-right: 50px;
padding-top: 5px;
padding-bottom: 5px;
background: #fff;
border-top: 1px solid rgba(0,0,0,0.1);


@media (max-width: 900px) { 
  padding-left: 25px;
padding-right: 25px;

.fff {
  flex-direction: row;

  padding-top: 10px;
padding-bottom: 10px;

  .bflex-1 {
    padding-left: 0px;
  }
}

  }

`;


const Footer = ({ siteTitle }) => (
  <Wrapper>

  <footer>
    <div className="container mx-auto">
      <div className="fff w-full flex flex-col md:flex-row py-4">
       
        <div className="bflex-1">
          <ul className="list-reset">
            <li className="mt-0 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/impressum"
                className="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Impressum
              </Link>
            </li>
          </ul>
        </div>
        <div className="bflex-1">
          <ul className="list-reset">
            <li className=" inline-block mr-2 md:block md:mr-0">
              <Link
                to="/datenschutz"
                className="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Datenschutz
              </Link>
            </li>
          </ul>
        </div>

        <div className="bflex-1">
          <ul className="list-reset">
            <li className=" inline-block mr-2 md:block md:mr-0">
              <Link
                to="/kontakt"
                className="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  </Wrapper>

);

export default Footer;
