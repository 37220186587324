import { Link } from "gatsby";
import React from "react";
import CTALink from "./CTALink";

import styled from 'styled-components'

import MobileMenu from "./MobileMenu/index";

const Wrapper = styled.div`
position: fixed;
top: 0px;
left: 0px;
z-index: 9999;
width: 100%;
display: flex;
align-items: left;
justify-content: space-between;
padding-left: 50px;
padding-right: 50px;
padding-top: 20px;
padding-bottom: 15px;
background: #fff;
height: auto;
min-height: 88px;
background-color: #fff;
border-bottom: 1px solid rgba(0,0,0,0.2);

@media (max-width: 1100px) {
  flex-direction: column;
}

@media (max-width: 900px) { 
   
padding-left: 25px;
padding-right: 25px;
  }



`;

const Menu = styled.div`
  color: #000;

  ul {
    padding-top: 21px;
    display: flex;
    li {
      padding-right: 10px;
    }
  }

  @media (max-width: 1100px) { 
    ul {
    padding-top: 10px;
    }
  }
  display: block;
  @media (max-width: 900px) { 
    display: none;
  }

`;

const MobMenu = styled.div`
position: absolute;
top: 0px;
left: 0px;
right: 0px;
bottom: 0px;
display: none;
@media (max-width: 900px) { 
    display: block;
  }
`;

const Logo = styled.div`
background: #fff;
left: 25px;
color: #2d3748;
font-size: 35px;
font-weight: 700;

@media (max-width: 900px) { 
  position: absolute;
  z-index: 99999;
  }

`;


const Header = ({ showNav, siteTitle, scrolled, navMenuItems = [], textWhite = true }) => {

  scrolled = true;
  textWhite = true;

  let headerClass = "fixed w-full z-30 top-0 text-white bheader";
  headerClass += scrolled ? " bg-white shadow" : "";

  let navActionClass =
    "mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75";
  navActionClass += !textWhite || !scrolled ? " bg-white text-gray-800" : "";
  navActionClass += textWhite || scrolled ? " gradient text-white" : "";

  let navContentClass =
    "w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 text-black p-4 lg:p-0 z-20";
  navContentClass += !textWhite || !scrolled ? " lg:bg-transparent bg-gray-100" : "";
  navContentClass += textWhite || scrolled ? " bg-white" : "";

  let titleClass = "toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl";
  titleClass += !textWhite || scrolled ? " text-gray-800" : "";
  titleClass += textWhite || !scrolled ? " text-white" : "";


  return (
    
      <Wrapper>
           <Logo>

          
<Link id="siteTitle"  to="/">
  {siteTitle}
</Link>
</Logo>

         
          <Menu >
            <ul>
              {navMenuItems.map((i, index) => (
                <li key={'hli'+index}>
                  <CTALink {...i} buttonActionClass={navActionClass} />
                </li>
              ))}
            </ul>
          </Menu>

         

<MobMenu>

<MobileMenu navMenuItems={navMenuItems} navActionClass={navActionClass}/>
</MobMenu>
          

        {showNav && navMenuItems && scrolled === true && (
          <>
         
          </>
        )}
     </Wrapper>

     
  );
};

export default Header;
